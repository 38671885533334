import React from 'react';
import PropTypes from 'prop-types';

const SoundcloudEmbed = ({ trackId }) => (
  <iframe
    title="Audio player"
    width="100%"
    height="190"
    scrolling="no"
    frameBorder="no"
    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false`}
  />
);

SoundcloudEmbed.propTypes = {
  trackId: PropTypes.string.isRequired,
};

export default SoundcloudEmbed;
