import React from 'react';
import PropTypes from 'prop-types';
import TabLink from '../../../elements/Link/TabLink';
import styles from './styles.module.css';

const ArtJameelCredit = ({ className = '', ...props }) => (
  <div className={`${styles.credit} ${className}`} {...props}>
    <p>
      This video was kindly provided by kind permission of
      {' '}
      <TabLink href="https://artjameel.org/">Art Jameel</TabLink>
      {' '}
and were filmed at the
      {' '}
      <TabLink href="https://artjameel.org/heritage/cairo/">
        Jameel House of Traditional Arts/Cairo
      </TabLink>
      , an Art Jameel initiative in partnership with
      {' '}
      <TabLink href="https://www.psta.org.uk/">
        The Prince&#39;s Foundation School of Traditional Arts
      </TabLink>
      {' '}
      and the Cultural Development Fund of Egypt.
    </p>
    <ul>
      <li>Twitter: @art_jameel</li>
      <li>Instagram: @art_jameel, @jhtacairo</li>
      <li>
        Facebook:
        {' '}
        <TabLink href="https://www.facebook.com/ArtJameelOrg/">Art Jameel</TabLink>
,
        {' '}
        <TabLink href="https://www.facebook.com/jhtacairo/">
          Jameel House of Traditional Arts/Cairo
        </TabLink>
      </li>
    </ul>
  </div>
);

ArtJameelCredit.defaultProps = {
  className: '',
};

ArtJameelCredit.propTypes = {
  className: PropTypes.string,
};

export default ArtJameelCredit;
