/* eslint camelcase: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import PageTitle from '../../elements/PageTitle';
import Container from '../../components/core/Container';
import BackLink from '../../components/core/BackLink';
import SoundcloudEmbed from '../../components/media/SoundcloudEmbed';
import YoutubeEmbed from '../../components/media/YoutubeEmbed';
import Layout from '../../components/core/Layout';
import ArtJameelSocialLinks from '../../components/media/ArtJameelSocialLinks';

const Media = ({ data }) => {
  const {
    title, media_id, external_id, desc, type,
  } = data.mediaJson;

  const renderDesc = () => ({
    __html: desc,
  });

  const isArtJameel = id => id === '9' || id === '10' || id === '11';

  const embed = (mediaType) => {
    switch (mediaType) {
      case 'audio':
        return <SoundcloudEmbed trackId={external_id} />;
      case 'video':
        return <YoutubeEmbed videoId={external_id} />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Container>
        <PageTitle>{title}</PageTitle>
        <BackLink text="Back" />
        <div style={{ marginTop: '1rem' }}>{embed(type)}</div>
        <p
          style={{ marginTop: '2rem', marginBottom: '2em' }}
          dangerouslySetInnerHTML={renderDesc()}
        />
        {isArtJameel(media_id) && <ArtJameelSocialLinks />}
      </Container>
    </Layout>
  );
};

Media.propTypes = {
  data: PropTypes.shape({
    mediaJson: PropTypes.object.isRequired,
  }).isRequired,
};

export default Media;

export const MediaDetailQuery = graphql`
  query MediaDetailQuery($media_id: String!) {
    mediaJson(media_id: { eq: $media_id }) {
      media_id
      external_id
      title
      desc
      type
    }
  }
`;
