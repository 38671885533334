import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveEmbed from 'react-responsive-embed';

const YoutubeEmbed = ({ videoId }) => (
  <ResponsiveEmbed
    allowFullScreen
    src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
  />
);

YoutubeEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
